<template>
  <svg
    v-if="$theme == 1"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.625 11.7321H13.375V6.48209C13.375 6.25002 13.2828 6.02746 13.1187 5.86337C12.9546 5.69927 12.7321 5.60709 12.5 5.60709C12.2679 5.60709 12.0454 5.69927 11.8813 5.86337C11.7172 6.02746 11.625 6.25002 11.625 6.48209V11.7321H6.375C6.14294 11.7321 5.92038 11.8243 5.75628 11.9884C5.59219 12.1525 5.5 12.375 5.5 12.6071C5.5 12.8392 5.59219 13.0617 5.75628 13.2258C5.92038 13.3899 6.14294 13.4821 6.375 13.4821H11.625V18.7321C11.625 18.9642 11.7172 19.1867 11.8813 19.3508C12.0454 19.5149 12.2679 19.6071 12.5 19.6071C12.7321 19.6071 12.9546 19.5149 13.1187 19.3508C13.2828 19.1867 13.375 18.9642 13.375 18.7321V13.4821H18.625C18.8571 13.4821 19.0796 13.3899 19.2437 13.2258C19.4078 13.0617 19.5 12.8392 19.5 12.6071C19.5 12.375 19.4078 12.1525 19.2437 11.9884C19.0796 11.8243 18.8571 11.7321 18.625 11.7321Z"
      fill="#494949"
    />
  </svg>
  <svg
    v-if="$theme == 2"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 11.0001H13.5V5.00009C13.5 4.73488 13.3946 4.48052 13.2071 4.29298C13.0196 4.10545 12.7652 4.00009 12.5 4.00009C12.2348 4.00009 11.9804 4.10545 11.7929 4.29298C11.6054 4.48052 11.5 4.73488 11.5 5.00009V11.0001H5.5C5.23478 11.0001 4.98043 11.1054 4.79289 11.293C4.60536 11.4805 4.5 11.7349 4.5 12.0001C4.5 12.2653 4.60536 12.5197 4.79289 12.7072C4.98043 12.8947 5.23478 13.0001 5.5 13.0001H11.5V19.0001C11.5 19.2653 11.6054 19.5197 11.7929 19.7072C11.9804 19.8947 12.2348 20.0001 12.5 20.0001C12.7652 20.0001 13.0196 19.8947 13.2071 19.7072C13.3946 19.5197 13.5 19.2653 13.5 19.0001V13.0001H19.5C19.7652 13.0001 20.0196 12.8947 20.2071 12.7072C20.3946 12.5197 20.5 12.2653 20.5 12.0001C20.5 11.7349 20.3946 11.4805 20.2071 11.293C20.0196 11.1054 19.7652 11.0001 19.5 11.0001Z"
      fill="#494949"
    />
  </svg>
  <svg
    v-if="$theme == 3"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 11.0001H13.5V5.00009C13.5 4.73488 13.3946 4.48052 13.2071 4.29298C13.0196 4.10545 12.7652 4.00009 12.5 4.00009C12.2348 4.00009 11.9804 4.10545 11.7929 4.29298C11.6054 4.48052 11.5 4.73488 11.5 5.00009V11.0001H5.5C5.23478 11.0001 4.98043 11.1054 4.79289 11.293C4.60536 11.4805 4.5 11.7349 4.5 12.0001C4.5 12.2653 4.60536 12.5197 4.79289 12.7072C4.98043 12.8947 5.23478 13.0001 5.5 13.0001H11.5V19.0001C11.5 19.2653 11.6054 19.5197 11.7929 19.7072C11.9804 19.8947 12.2348 20.0001 12.5 20.0001C12.7652 20.0001 13.0196 19.8947 13.2071 19.7072C13.3946 19.5197 13.5 19.2653 13.5 19.0001V13.0001H19.5C19.7652 13.0001 20.0196 12.8947 20.2071 12.7072C20.3946 12.5197 20.5 12.2653 20.5 12.0001C20.5 11.7349 20.3946 11.4805 20.2071 11.293C20.0196 11.1054 19.7652 11.0001 19.5 11.0001Z"
      fill="#494949"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    fillColor: {
      type: String,
    },
  },
});
</script>
